import { registerFunctionComponent } from '/js/lib/webact.js';

import '../search-box/search-box.js';

function SideMenu () {
  const { html, css, postRender, $ } = this;

  html`
    <search-box small-size></search-box>
    <ol>
      <li>
        <nattramn-link href="/">
          <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M7.5.5l.325-.38a.5.5 0 00-.65 0L7.5.5zm-7 6l-.325-.38L0 6.27v.23h.5zm5 8v.5a.5.5 0 00.5-.5h-.5zm4 0H9a.5.5 0 00.5.5v-.5zm5-8h.5v-.23l-.175-.15-.325.38zM1.5 15h4v-1h-4v1zm13.325-8.88l-7-6-.65.76 7 6 .65-.76zm-7.65-6l-7 6 .65.76 7-6-.65-.76zM6 14.5v-3H5v3h1zm3-3v3h1v-3H9zm.5 3.5h4v-1h-4v1zm5.5-1.5v-7h-1v7h1zm-15-7v7h1v-7H0zM7.5 10A1.5 1.5 0 019 11.5h1A2.5 2.5 0 007.5 9v1zm0-1A2.5 2.5 0 005 11.5h1A1.5 1.5 0 017.5 10V9zm6 6a1.5 1.5 0 001.5-1.5h-1a.5.5 0 01-.5.5v1zm-12-1a.5.5 0 01-.5-.5H0A1.5 1.5 0 001.5 15v-1z" fill="currentColor"></path></svg>
          <i18n-text key="menuHome"></i18n-text>
        </nattramn-link>
      </li>
    </ol>
    <strong><i18n-text key="menuLibrary"></i18n-text></strong>
    <ol>
      <li disabled>
        <nattramn-link href="/feed-updates">
        <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M.5 11.5L5 7l3 3 6.5-6.5m0 0V10m0-6.5H8" stroke="currentColor"></path></svg>
          <i18n-text key="menuLatestUpdates"></i18n-text>
        </nattramn-link>
      </li>
      <li>
        <nattramn-link href="/subscriptions">
        <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M12.5 14.5l-.312.39A.5.5 0 0013 14.5h-.5zm0-14h.5V0h-.5v.5zm-10 0V0H2v.5h.5zm0 14H2a.5.5 0 00.812.39L2.5 14.5zm5-4l.312-.39a.5.5 0 00-.624 0l.312.39zm5.5 4V.5h-1v14h1zM2 .5v14h1V.5H2zm.812 14.39l5-4-.624-.78-5 4 .624.78zm4.376-4l5 4 .624-.78-5-4-.624.78zM12.5 0h-10v1h10V0z" fill="currentColor"></path></svg>
          <i18n-text key="menuSubscriptions"></i18n-text>
        </nattramn-link>
      </li>
      <li>
        <nattramn-link href="/in-progress">
          <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M7.5 7.5H7a.5.5 0 00.146.354L7.5 7.5zm0 6.5A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zM14 7.5A6.5 6.5 0 017.5 14v1A7.5 7.5 0 0015 7.5h-1zM7.5 1A6.5 6.5 0 0114 7.5h1A7.5 7.5 0 007.5 0v1zm0-1A7.5 7.5 0 000 7.5h1A6.5 6.5 0 017.5 1V0zM7 3v4.5h1V3H7zm.146 4.854l3 3 .708-.708-3-3-.708.708z" fill="currentColor"></path></svg>
          <i18n-text key="menuInProgress"></i18n-text>
        </nattramn-link>
      </li>
      <li disabled>
        <nattramn-link href="/downloads">
          <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M7.5 10.5l-3.25-3m3.25 3l3-3m-3 3V1m6 6v6.5h-12V7" stroke="currentColor"></path></svg>
          <i18n-text key="menuDownloaded"></i18n-text>
        </nattramn-link>
      </li>
    </ol>
  `;

  css`
    :host {
      --background-main: #F6F6F6;
      --background-item-hover: #DCDCDC;
      --header-color: #B6B6B6;
      --border-color: #D9D9D9;
      position: fixed;
      top: 51px;
      left: 0;
      bottom: 0;
      min-width: 200px;
      width: 50vw;
      pointer-events: none;
      transform: translateX(-120%);
      z-index: 10;
      transition: transform 250ms ease;
      will-change: transform;
      border-left: 1px solid var(--border-color);
      background-color: var(--background-main);
    }

    @media (prefers-color-scheme: dark) {
      :host {
        --background-main: #141414;
        --background-item-hover: #2E2E2E;
        --header-color: #444444;
        --border-color: #000000;
      }
    }

    :host(.open) {
      pointer-events: auto;
      transform: none;
    }

    ol {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: block;
      content-visibility: auto;
      contain-intrinsic-size: 207.094px 32.250px;
    }

    li[disabled] {
      pointer-events: none;
      opacity: 0.5;
      filter: grayscale(100%);
    }

    svg {
      width: 10pt;
      color: var(--app-colour);
      margin-right: 1em;
    }

    strong {
      display: block;
      color: var(--header-color);
      padding: calc(var(--gr-rem) / 3) calc(var(--gr-rem) / 2);
      margin: calc(var(--gr-rem) / 4) calc(var(--gr-rem) / 3) 0;
    }

    search-box {
      display: block;
      margin: calc(var(--gr-rem) / 2) calc(var(--gr-rem) - 6px);
    }

    nattramn-link {
      padding: calc(var(--gr-rem) / 3) calc(var(--gr-rem) / 2);
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      border-radius: calc(var(--gr-rem) / 4);
      margin: 0 calc(var(--gr-rem) / 3);
    }

    nattramn-link:hover,
    nattramn-link:active {
      background-color: var(--background-item-hover);
    }

    @media (min-width: 984px) {
      :host {
        position: static;
        transform: none;
        width: calc(var(--gr-rem) * 8);
        height: 100vh;
        font-size: 8pt;
        pointer-events: auto;
        z-index: 0;
      }
    }
  `;

  const toggleOpenState = () => {
    if (window.innerWidth >= 984) {
      return;
    }

    $().classList.toggle('open');
    document.body.classList.toggle('side-menu-open');
  };

  postRender(() => {
    document.addEventListener('side-menu:toggle', toggleOpenState, false);
    $().addEventListener('click', toggleOpenState, false);
  });
}

export default registerFunctionComponent(SideMenu, {
  name: 'side-menu'
});
